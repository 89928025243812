import 'bootstrap/dist/css/bootstrap.min.css';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Container, Navbar, Table } from 'react-bootstrap';
import apiService from '../services/ApiService';

// Define types for result data
interface Result {
  resultTime: string;
  result: string;
  resultDate: string;
  siteName: string;
}

// Define the types for the data we will be working with
interface SiteList {
  id: number;
  siteName: string;
}

interface SiteMapping {
  siteId: number;
  siteName: string;
  timeId: number;
  resultTime: string;
}

interface Site {
  siteId: number;
  siteName: string;
  resultTimes: string[];
}

const groupBySiteName = (data: Result[]): { [key: string]: Result[] } => {
  return data.reduce((groups: { [key: string]: Result[] }, item: Result) => {
    const group = groups[item.siteName] || [];
    group.push(item);
    groups[item.siteName] = group;
    return groups;
  }, {});
};

const ResultPage = () => {
  const [dataSourceTodayResult, setDataSourceTodayResult] = useState<Result[]>([]);
  const [dataSourceCurrentResult, setDataSourceCurrentResult] = useState<Result>();
  const [dataSourcePrevResult, setDataSourcePrevResult] = useState<Result[]>([]);
  const [message, setMessage] = useState<string>('Welcome to Smartmumbai Matka!');

  const bg = 'rgb(252, 76, 78)';  // Updated to black background
  const bgTextColor = 'white';  // White text color
  const tableBg = '#6c5c58';  // example table background
  const tableText = 'white';  // White text color for tables
  const footerBg = '#343a40';  // footer background color
  const footerText = 'white';  // footer text color
  const disclaimerBg = '#495057';  // disclaimer background color
  const disclaimerText = 'white';  // disclaimer text color
  const siteId = 7;

  useEffect(() => {
    // Fetch data using the apiService
    apiService.getTodayResult(siteId)
      .then(data => setDataSourceTodayResult(data))
      .catch(error => console.error('Error fetching today\'s results:', error));

    apiService.getPreviousResult(siteId, 7)
      .then(data => setDataSourcePrevResult(data))
      .catch(error => console.error('Error fetching last day\'s results:', error));

    apiService.getCurrentResult(siteId)
      .then(data => setDataSourceCurrentResult(data))
      .catch(error => console.error('Error fetching last day\'s results:', error));

    apiService.getDisclaimer(siteId)
      .then(data => setMessage(data))
      .catch(error => console.error('Error fetching disclaimer:', error));
  }, []);

  const groupedData = _.groupBy(dataSourcePrevResult, 'resultDate');

  return (
    <div style={{ backgroundColor: bg, color: bgTextColor }}>
      <Navbar bg="dark" variant="dark" className="mb-4" style={{ justifyContent: 'space-between', padding: '1rem 2rem' }}>
        <Navbar.Brand className='font-weight-bold' style={{ color: '#fff', fontWeight: '600' }}>Mumbai Matka</Navbar.Brand>
      </Navbar>

      <Container>
        {/* Today Result */}
        <section className="mb-5">

          <div className="column p-3 d-flex flex-column align-items-center">
            <h5 className='font-weight-bold mb-1 text-center'>CURRENT RESULT</h5>
            <div>Time: {dataSourceCurrentResult?.resultTime}</div>
            <div>Result: {dataSourceCurrentResult?.result}</div>
          </div>

          <h5 className='font-weight-bold mb-3 text-center mt-5'>Today Result</h5>
          {Object.entries(groupBySiteName(dataSourceTodayResult)).map(([siteName, results]) => (
            <div key={siteName}>
              <div className="table-responsive">
                <Table bordered striped>
                  <thead style={{ backgroundColor: bg, color: tableText }}>
                    <tr>
                      <th>Date</th>
                      {results.map((result) => (
                        <th key={result.resultTime}>{result.resultTime}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ backgroundColor: tableBg, color: tableText }}>
                      <td>{results[0]?.resultDate}</td>
                      {results.map((result) => (
                        <td key={result.resultTime}>{result.result}</td>
                      ))}
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          ))}
        </section>

        {/* Last Day Result */}
        <section className="mb-5">
          <h5 className='font-weight-bold mb-3 text-center'>Previous Week Results</h5>
          {Object.keys(groupedData).map((date, index) => (
            <div key={index} className="table-responsive">
              <Table striped bordered hover responsive className="w-100">
                <thead style={{ backgroundColor: tableBg, color: tableText }}>
                  <tr className="font-weight-bold">
                    <th>Date</th>
                    {groupedData[date].map((item, idx) => (
                      <th key={idx}>{item.resultTime}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ backgroundColor: tableBg, color: tableText }}>
                    <td>{date}</td>
                    {groupedData[date].map((item, idx) => (
                      <td key={idx}>{item.result}</td>
                    ))}
                  </tr>
                </tbody>
              </Table>
            </div>
          ))}
        </section>

      </Container>

      {/* Footer */}
      <footer style={{ backgroundColor: footerBg, color: footerText }}>
        <Container className="text-center py-3">
          <p>Copyright © smartmumbaimatka.com 2020. All rights reserved.</p>
        </Container>
        <Navbar style={{ backgroundColor: disclaimerBg, color: disclaimerText }}>
          <Navbar.Text>
            <div className="marquee">
              {message}
            </div>
          </Navbar.Text>
        </Navbar>
      </footer>

      {/* CSS for Scrolling Text */}
      <style>{`
        .marquee {
          display: inline-block;
          white-space: nowrap;
          animation: marquee 10s linear infinite;
        }

        @keyframes marquee {
          from {
            transform: translateX(100%);
          }
          to {
            transform: translateX(-100%);
          }
        }
      `}</style>
    </div>
  );
};

export default ResultPage;
