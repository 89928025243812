import React, { FunctionComponent } from "react";

interface Error404Props {
  
}
 
const Error404: FunctionComponent<Error404Props> = () => {
  return (
    <>
      <h1>404 Page</h1>
    </>
  );
}
 
export default Error404;