import buttonStyles from './buttonStyle.json';

const theme = {
    primary: '#3498db',
    secondary: '#2ecc71',
    background: '#ecf0f1',
    text: '#2c3e50',
    spacing: {
      small: '8px',
      medium: '16px',
      large: '24px',
    },
    colors: {
      danger: '#dc3545',
      success: '#28a745',
      // Add more colors as needed
    },
    borderRadius: '4px',
    boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.15)',
    buttonStyles
  };
  
  export default theme;
  