import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.css';
import { ThemeProvider } from './theme/ThemeContext';
import routes from './utils/routes';



const App: React.FC = () => {

  const router = createBrowserRouter(routes)

  return (
      <ThemeProvider>
        <RouterProvider router={router} />
      </ThemeProvider>


  );
}

export default App;
