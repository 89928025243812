import axios from 'axios';

// Base URL for your API
const baseUrl = process.env.REACT_APP_BASE_URL || 'http://api.mahadevmatka.com/matka-api/api'; // replace with your actual base URL

// API Service
const ApiService = {
  // Get Today's Result
  getTodayResult: async (siteId) => {
    return axios.get(`${baseUrl}/result/today/${siteId}`)
      .then(response => response.data)
      .catch(error => {
        console.error('Error fetching today\'s result:', error);
        throw error;
      });
  },


  // Get Previous Result for a specific site and number of days
  getPreviousResult: async (siteId, days) => {
    return axios.get(`${baseUrl}/result/prev/${siteId}/${days}`)
      .then(response => response.data)
      .catch(error => {
        console.error('Error fetching previous result:', error);
        throw error;
      });
  },

  // Get Disclaimer for a specific site
  getDisclaimer: async (siteId) => {
    return axios.get(`${baseUrl}/disclaimer/${siteId}`)
      .then(response => response.data)
      .catch(error => {
        console.error('Error fetching disclaimer:', error);
        throw error;
      });
  },

  getCurrentResult: async (siteId) => {
    return axios.get(`${baseUrl}/result/current/${siteId}`)
    .then(response => response.data)
    .catch(error => {
      console.error('Error fetching sites:', error);
      throw error;
    });
  }
};

export default ApiService;
