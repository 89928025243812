import { RouteObject } from "react-router-dom";
import Error404 from '../pages/Error404';
import ResultPage from "../pages/ResultPage";

const routes: RouteObject[] = [
  {
    path: "/",
    element: <ResultPage />,
    errorElement: <Error404 />,
  },
];

export default routes;