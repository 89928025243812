import { createContext, FC, useContext } from 'react';
import theme from './theme';

const ThemeContext = createContext(theme);

interface ThemeContextProps {
    children: any;
}

export const ThemeProvider: FC<ThemeContextProps> = ({ children}) => {
  return (
    <ThemeContext.Provider value={theme}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
